.plug {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(0deg, #111111 0%, #3D1600 100%);;
  color: #fff;
  font-size: 24px;
  line-height: 32px;
}
