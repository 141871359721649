@import 'variables';

.container {
  margin: 24px 0;
  font-size: 14px;
}

.attributes {
  color: $text;
  font-size: 12px;

  div {
    display: inline-block;
    margin-right: 4px;
  }

  a:link,
  a:visited {
    color: $text;
  }
}
