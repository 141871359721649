.button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.info {
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 5px 0 #c1c1c1;
  border-radius: 3px;
  max-width: 200px;
}

.title {
  margin: 0;
  font-size: 13px;
}

.address {
  margin-top: 10px;
  font-size: 13px;
}
