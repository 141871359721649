@import 'variables';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-width: 360px;
  padding: 13px 16px;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  overflow: auto;
  font-size: 14px;
  line-height: 18px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  padding-right: 40px;
}

.close {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.title {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}

.destroyed {
  color: red;
  font-weight: bold;
  margin-bottom: 16px;

  svg {
    margin: 0 0 -3px 0;
  }

  span {
    margin-left: 2px;
  }
}

.mainInfo {
  display: block;
  margin-block-start: 0;
  margin-block-end: 10px;

  &:last-of-type {
    margin-block-end: 16px;
  }
}

.info {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 18px;

  & + .info {
    margin-top: 10px;
  }

  svg {
    flex: 0 0 auto;
    opacity: 0.4;
  }
}

.text {
  margin-left: 14px;
  color: inherit;
  opacity: 0.6;
}

.license {
  margin-top: 40px;
  color: $text;
  font-size: 12px;

  a:link,
  a:visited {
    color: $text;
  }
}

.egrokn {
  background: #ddd;
  font-size: 11px;
  line-height: 16px;
  border-radius: 2px;
  padding: 0 3px;
  display: inline-block;
  white-space: nowrap;
  cursor: help;
  letter-spacing: 0.5px;
}
