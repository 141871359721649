@import 'variables';

.navigationControl {
  position: absolute;
  top: 118px;
  right: 10px;
}

.geolocateControl {
  position: absolute;
  top: 75px;
  right: 10px;
}

.loading {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10000;
  font-size: 13px;
  background-color: #fff;
  border-radius: 3px;
  padding: 3px 5px;
}

.cluster {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #fff;
  background: $primary;
  border-radius: 20px;
  border: none;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  outline: none;

  &:hover {
    background: $primaryHover;
  }
}
